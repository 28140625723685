import React from 'react';
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader,
    CustomMarker,
    TimelineMarkers,
} from "react-calendar-timeline/lib";
import moment from 'moment';
import 'moment-timezone';  // Import moment-timezone
import 'react-calendar-timeline/lib/Timeline.css';
import './Timeline.css'

const groups = [{ id: 1, title: '' }];

const CustomTimeline = (props) => {
    const today = moment().tz('Asia/Tokyo').startOf('day');
    const startDate = today.clone().add(-3, 'days').toDate();
    const endDate = today.clone().add(56, 'days').toDate();

    const handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
        // Maintain the scroll capability while preventing view mode change
        updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    };

    const convertedData = props.data.map(item => ({
        ...item,
        start_time: moment(item.start_time).add(1, 'day').startOf('day'),
        end_time: moment(item.end_time).add(1, 'day').startOf('day'),
    }));

    const jstDate = new Date();
    jstDate.setHours(12, 0, 0, 0); // Set time to 12:00 PM in local time

    return (
        <div className={'timeline-container'}>
            <Timeline
                groups={groups}
                items={convertedData || []}
                defaultTimeStart={startDate}
                defaultTimeEnd={endDate}
                canMove={false}
                canResize={false}
                canChangeGroup={false} // Disable changing item groups
                onItemClick={(itemId, e, time) => ()=>{}} // Prevent item clicks
                onItemSelect={() => {}} // Prevent item selection
                onTimeChange={handleTimeChange} // Allow scrolling, but keep the view fixed
                timeSteps={{ day: 1 }} // Show days
                showCursorLine // Keep the cursor line to enhance the UX
                itemRenderer={({ item, itemContext, getItemProps }) => {
                    const itemStyle = {
                        background: item.color || 'transparent', // Transparent background if not defined
                        color: 'white',
                        boxSizing: 'border-box',
                        border: item.borderColor && item.borderWidth ? `${item.borderWidth} solid ${item.borderColor}` : 'none', // Apply custom border if defined
                        marginTop: item.marginTop ? `${item.marginTop}` : '0px', // Add margin to top if
                        marginBottom: item.marginBottom ? `${item.marginBottom}` : '0px',
                        padding: item.padding ? `${item.padding}` : '0px',
                    };
                    return (
                        <div
                            {...getItemProps({
                                style: itemStyle,
                            })}
                        >
                            <span>{item.title}</span>
                        </div>
                    );
                }}
            >
                <TimelineHeaders>
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div {...getRootProps()} style={{textAlign:'center', justifyContent:'center'}}>{props.title}</div>;
                        }}
                    </SidebarHeader>
                    <DateHeader unit="month" />
                    <DateHeader unit="day"/>
                </TimelineHeaders>
                <TimelineMarkers>
                    <CustomMarker date={today.clone().add(1, 'days').startOf('day')} >
                        {({ styles, date }) => {
                            const customStyles = {
                                ...styles,
                                backgroundColor: '#DC000C',
                                width: '2px',
                                zIndex: 100
                            }
                            return (
                                <div style={customStyles} >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: '-2px', // Center the circle horizontally
                                            width: '5px',
                                            height: '5px',
                                            backgroundColor: '#DC000C',
                                            borderRadius: '50%' // Makes the div a circle
                                        }}
                                    />
                                </div>
                            )
                        }}
                    </CustomMarker>
                </TimelineMarkers>
            </Timeline>
        </div>
    );
};

export default CustomTimeline;