import * as THREE from 'three'
import { OrbitControls, ViewHelper } from 'three/examples/jsm/Addons.js'
import GUI from 'three/examples/jsm/libs/lil-gui.module.min.js'

export default class THREEGL {
  constructor(canvas_width, canvas_height, GL_HOLDER, GUI_ELEM_ID) {
    if (GL_HOLDER.hasChildNodes()) {return}

    this.scene = null
    this.renderer = null
    this.camera = null
    this.controls = null
    this.viewHelper = null
    this.width = undefined
    this.height = undefined
    this.gui = null
    this.GL_HOLDER = GL_HOLDER
    this.GUI_ELEM_ID = GUI_ELEM_ID

    this.lightings = []
    this.lightingHelpers = []
    this.axesHelper = new THREE.AxesHelper(10)

    this.XMLSurfaceMeshes = []

    this.numPointsController = undefined
    this.numSurfacesController = undefined
    this.minZController = undefined
    this.maxZController = undefined

    this.renderFlag = true
    this.wireframeEnabled = false
    this.edgeWireframeEnabled = true

    THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 0, 1)

    this.width = canvas_width
    this.height = canvas_height
    this.orthoWidth = 0
    this.orthoHeight = 0
    this.scene = this.CreateScene()
    this.renderer = this.CreateRenderer(this.width, this.height, 0xe6f3ff)

    // Define dimensions of the view volume
    const viewWidth = this.width / 2
    const viewHeight = this.height / 2

    // Define the dimensions of the bounding box that contains the object
    const objectWidth = this.width / 10
    const objectHeight = this.height / 10

    // Calculate the scale to fit the object within the view volume
    const scale = Math.max(objectWidth / viewWidth, objectHeight / viewHeight)

    // Calculate the new dimensions of the view volume based on the scaled object dimensions
    const orthoWidth = viewWidth * scale
    const orthoHeight = viewHeight * scale

    // Create the orthographic camera
    this.camera = new THREE.OrthographicCamera(
      -orthoWidth / 2, // left
      orthoWidth / 2, // right
      orthoHeight / 2, // top
      -orthoHeight / 2, // bottom
      1,
      10000
    )

    // Position the camera to view from the top
    this.SetCamera()

    this.controls = this.CreateControls(
      this.camera,
      this.renderer.domElement,
      {
        LEFT: 'ArrowLeft',
        UP: 'ArrowUp',
        RIGHT: 'ArrowRight',
        BOTTOM: 'ArrowDown',
      },
      true
    )
    this.viewHelper = this.CreateViewHelper()

    this.gui = this.CreateGUI()

    this.GenerateLightingAndHelpers()
    this.AddLightings()
    // this.AddAxisHelper();
    this.GL_HOLDER.appendChild(this.renderer.domElement)

    this.Animate()
  }

  SetCamera() {
    // Position the camera to view from the top
    // Adjust the z position as needed to position the camera properly above the object
    this.camera.position.set(0, 0, 1) // Adjust the z position as needed

    // Set the camera's up vector to point towards the positive y-axis to view from the top
    this.camera.up.set(0, 1, 0) // Change to positive y-axis

    // Point the camera at the origin (assuming the object is centered at the origin)
    this.camera.lookAt(0, 0, 0)

    this.camera.updateProjectionMatrix()
  }

  Animate() {
    requestAnimationFrame(() => {
      this.Animate()
    })
    // Only re-render if flag is true
    if (!this.renderFlag) return

    this.renderer.clear()

    // required if controls.enableDamping or controls.autoRotate are set to true
    this.controls.update()

    this.renderer.render(this.scene, this.camera)

    this.viewHelper.render(this.renderer)
  }

  TranslateXMLSurfaceMeshToCenter() {
    if (this.XMLSurfaceMeshes.length === 0) return // No meshes to center

    let xSum = 0,
      ySum = 0,
      zSum = 0
    let totalVertexCount = 0

    // Calculate the overall centroid
    for (const mesh of this.XMLSurfaceMeshes) {
      const geometry = mesh.geometry
      const positionAttribute = geometry.getAttribute('position')
      totalVertexCount += positionAttribute.count

      for (let i = 0; i < positionAttribute.count; i++) {
        xSum += positionAttribute.getX(i)
        ySum += positionAttribute.getY(i)
        zSum += positionAttribute.getZ(i)
      }
    }

    const overallCentroid = new THREE.Vector3(
      xSum / totalVertexCount,
      ySum / totalVertexCount,
      zSum / totalVertexCount
    )

    // Translate each mesh and its children (LineSegments) to center at the overall centroid
    for (const mesh of this.XMLSurfaceMeshes) {
      const geometry = mesh.geometry
      const positionAttribute = geometry.getAttribute('position')
      const newPositions = new Float32Array(positionAttribute.count * 3)

      for (let i = 0; i < positionAttribute.count; i++) {
        newPositions[i * 3] = positionAttribute.getX(i) - overallCentroid.x
        newPositions[i * 3 + 1] = positionAttribute.getY(i) - overallCentroid.y
        newPositions[i * 3 + 2] = positionAttribute.getZ(i) - overallCentroid.z
      }

      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(newPositions, 3)
      )
      geometry.computeVertexNormals()

      // Move child LineSegments if they exist
      mesh.children.forEach((child) => {
        if (child instanceof THREE.LineSegments) {
          child.position.sub(overallCentroid) // Move the child lines by the same offset
        }
      })
    }
  }

  LoadSurfaceXMLMaps(GL_Object) {
    // Unload previous XML Meshes
    this.UnloadAllSurfaceXMLMaps()

    let numPoints = 0
    let numSurfaces = 0
    let z = []

    Object.keys(GL_Object).forEach((surface_name) => {
      // Store surface Properties display
      numPoints += GL_Object[surface_name].vertices.length / 3
      numSurfaces += GL_Object[surface_name].indices.length / 3
      z.push(
        ...GL_Object[surface_name].vertices.filter(
          (_, index) => (index - 2) % 3 === 0 && index >= 2
        )
      )
      this.GenerateSurfaceXMLMap(GL_Object[surface_name])
    })

    // Set property for surface properties controller inside XML
    if (this.numPointsController) {
      this.numPointsController.setValue(numPoints)
    }
    if (this.numSurfacesController) {
      this.numSurfacesController.setValue(numSurfaces)
    }
    if (this.minZController) {
      this.minZController.setValue(Math.min.apply(null, z))
    }
    if (this.maxZController) {
      this.maxZController.setValue(Math.max.apply(null, z))
    }

    // Center all loaded meshes at once
    this.TranslateXMLSurfaceMeshToCenter()

    this.scene.add(...this.XMLSurfaceMeshes)
  }

  UnloadAllSurfaceXMLMaps() {
    if (this.XMLSurfaceMeshes.length <= 0) return

    for (let i = this.XMLSurfaceMeshes.length - 1; i >= 0; i--) {
      this.RemoveObject3D(this.XMLSurfaceMeshes[i])
      this.XMLSurfaceMeshes.pop()
    }

    // Position the camera to view from the top
    this.SetCamera()
  }

  UpdateGLSize(width, height) {
    this.width = width
    this.height = height

    // Update camera dimensions and aspect ratio
    const viewWidth = this.width / 2
    const viewHeight = this.height / 2

    const objectWidth = this.width / 10
    const objectHeight = this.height / 10

    const scale = Math.max(objectWidth / viewWidth, objectHeight / viewHeight)

    this.orthoWidth = viewWidth * scale
    this.orthoHeight = viewHeight * scale

    this.camera.left = -this.orthoWidth / 2
    this.camera.right = this.orthoWidth / 2
    this.camera.top = this.orthoHeight / 2
    this.camera.bottom = -this.orthoHeight / 2

    this.camera.position.set(0, 0, 1000)
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.width, this.height, false)
    this.ZoomToFitSurface()
  }

  GenerateSurfaceXMLMap(surfaceLayer) {
    const XMLSufaceMapMesh = this.CreateBufferMesh(surfaceLayer)
    this.XMLSurfaceMeshes.push(XMLSufaceMapMesh)

    return XMLSufaceMapMesh
  }

  CreateBufferMesh(surfaceLayer) {
    // Define the geometry (triangle)
    const geometry = new THREE.BufferGeometry()
    const vertices = new Float32Array(surfaceLayer.vertices)

    geometry.setIndex(surfaceLayer.indices)
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    const material = new THREE.MeshPhongMaterial({
      side: THREE.DoubleSide,
      transparent: false,
      color: 0xacacac,
      polygonOffset: true,
      polygonOffsetFactor: 1,
      polygonOffsetUnits: 1,
      wireframe: this.wireframeEnabled,
    })

    const triangle = new THREE.Mesh(geometry, material)

    // Call the function to convert the geometry to local coordinates
    let localGeometry = this.WorldToLocalBufferGeometry(geometry, triangle)

    triangle.geometry = localGeometry

    // Create wireframe based on local geometry
    const geo = new THREE.EdgesGeometry(triangle.geometry) // or WireframeGeometry
    const mat = new THREE.LineBasicMaterial({
      color: 0x000,
      visible: this.edgeWireframeEnabled,
    })
    const wireframe = new THREE.LineSegments(geo, mat)

    triangle.add(wireframe)

    return triangle
  }

  ZoomToFitSurface() {
    const boundingBox = new THREE.Box3()

    this.XMLSurfaceMeshes.forEach((mesh) => {
      boundingBox.expandByObject(mesh)
    })

    const size = boundingBox.getSize(new THREE.Vector3())
    const boxWidth = size.x
    const boxHeight = size.y

    // Assuming boundingBox is an instance of THREE.Box3
    const min = boundingBox.min
    const max = boundingBox.max

    const scale = 1 - (this.orthoHeight - boxHeight) / this.orthoHeight
    const newBoxWidth = this.orthoWidth * scale

    this.controls.reset()
    this.camera.position.set(0, 0, 1000)
    this.camera.left = -newBoxWidth / 2
    this.camera.right = newBoxWidth / 2
    this.camera.top = max.y
    this.camera.bottom = min.y
    this.camera.zoom = 0.98 // offset = 0.02

    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.width, this.height, false)
  }

  WorldToLocalBufferGeometry(geometry, object) {
    // Create a new matrix to hold the inverse of the object's world matrix
    const inverseMatrix = object.matrixWorld.invert()

    // Clone the geometry to avoid modifying the original
    const localGeometry = geometry.clone()

    // Get the array of vertices
    const positions = localGeometry.getAttribute('position')

    if (positions !== undefined) {
      const count = positions.count
      const itemSize = positions.itemSize

      // Apply the inverse transformation to each vertex
      for (let i = 0; i < count; i++) {
        const index = i * itemSize
        const vertex = new THREE.Vector3(
          positions.array[index],
          positions.array[index + 1],
          positions.array[index + 2]
        )

        vertex.applyMatrix4(inverseMatrix)

        positions.array[index] = vertex.x
        positions.array[index + 1] = vertex.y
        positions.array[index + 2] = vertex.z
      }

      // Notify Three.js that the positions have been updated
      positions.needsUpdate = true
    }

    return localGeometry
  }

  CreateViewHelper() {
    const viewHelper = new ViewHelper(this.camera, this.renderer.domElement)
    viewHelper.setLabels('X', 'Y', 'Z')

    return viewHelper
  }

  CreateScene() {
    const scene = new THREE.Scene()
    return scene
  }

  CreateRenderer(width, height, color) {
    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(width, height, false)
    renderer.autoClear = false
    renderer.setClearColor(color)
    return renderer
  }

  CreateControls(camera, dom, keys, _enableDamping) {
    const controls = new OrbitControls(camera, dom)
    controls.listenToKeyEvents(document.body)
    controls.keys = keys
    controls.saveState()
    return controls
  }

  CreateGUI() {
    if (document.getElementById(this.GUI_ELEM_ID).hasChildNodes()) { return }
    const gui = new GUI({
      container: document.getElementById(this.GUI_ELEM_ID),
      title: 'Property Explorer',
      width: 160,
    })

    const wireFrameFolder = gui.addFolder('Wireframe Control')
    wireFrameFolder
      .add(
        { edgeWireframeEnabled: this.edgeWireframeEnabled },
        'edgeWireframeEnabled'
      )
      .name('Show Edge')
      .onChange((value) => {
        this.XMLSurfaceMeshes.forEach((mesh) => {
          // Hide sudo wireframe that was added with polygons as well
          if (mesh.children.length > 0) {
            mesh.children.some((child) => {
              if (child instanceof THREE.LineSegments) {
                child.material.visible = value
                return
              }
            })
          }
        })
        this.wireframeEnabled = value
      })
    wireFrameFolder
      .add({ wireframeEnabled: this.wireframeEnabled }, 'wireframeEnabled')
      .name('Show Wireframe')
      .onChange((value) => {
        this.XMLSurfaceMeshes.forEach((mesh) => {
          // Toggle true wireframe
          if (mesh.material instanceof THREE.MeshPhongMaterial) {
            mesh.material.wireframe = value
          }
        })
        this.wireframeEnabled = value
      })

    const propertyFolder = gui.addFolder('Surface Properties')
    // Display total POINTS
    this.numPointsController = propertyFolder
      .add({ numberOfPoints: 0 }, 'numberOfPoints')
      .name('Points')

    // Display total SURFACES
    this.numSurfacesController = propertyFolder
      .add({ numberOfSurfaces: 0 }, 'numberOfSurfaces')
      .name('Surfaces')

    // Display min Z value
    this.minZController = propertyFolder.add({ minZ: 0 }, 'minZ').name('Min Z')

    // Display max Z value
    this.maxZController = propertyFolder.add({ maxZ: 0 }, 'maxZ').name('Max Z')

    // Disable for read only controller
    this.numPointsController.disable()
    this.numSurfacesController.disable()
    this.minZController.disable()
    this.maxZController.disable()

    // Default close
    gui.close()
    wireFrameFolder.close()
    propertyFolder.close()

    return gui
  }

  GenerateLightingAndHelpers() {
    // Add directional light to the scene
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    // Diretional light shines from top
    directionalLight.position.set(0, 60, 0)

    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x080820, 1)

    const directionalLightHelper = new THREE.DirectionalLightHelper(
      directionalLight,
      0.2 // Size
    )

    const hemisphereLightHelper = new THREE.HemisphereLightHelper(
      hemisphereLight,
      0.2 // Size
    )

    const ambientLight = new THREE.AmbientLight(0xffffff, 1)

    function light(i, x, y, z) {
      let l = new THREE.PointLight(0xffffff, i)
      l.position.set(x, y, z)
      return l
    }

    const extra_lights = [
      light(0.2, 30, 0, 0),
      light(0.2, -30, 0, 0),
      light(0.2, 0, 30, 0),
      light(0.2, 0, -30, 0),
      light(0.2, 0, 0, 30),
      light(0.2, 0, 0, -30),
    ]

    this.lightings = [
      directionalLight,
      hemisphereLight,
      ambientLight,
      ...extra_lights,
    ]
    this.lightingHelpers = [directionalLightHelper, hemisphereLightHelper]
  }

  AddAxisHelper() {
    this.scene.add(this.axesHelper)
  }

  AddObject(object) {
    this.scene.add(object)
  }

  AddLightings() {
    this.lightings.forEach((x) => this.AddObject(x))
  }

  RemoveLightings() {
    this.lightings.forEach((x) => this.RemoveObject3D(x))
  }

  AddLightingHelpers() {
    this.lightingHelpers.forEach((x) => this.AddObject(x))
  }

  RemoveLightingHelpers() {
    this.lightingHelpers.forEach((x) => this.RemoveObject3D(x))
  }

  RemoveObject3D(object3D) {
    if (!(object3D instanceof THREE.Object3D)) return false

    // for better memory management and performance

    if (object3D instanceof THREE.Mesh) {
      if (object3D.geometry) object3D.geometry.dispose()
      if (object3D.material instanceof Array) {
        // for better memory management and performance
        object3D.material.forEach((material) => material.dispose())
      } else {
        // for better memory management and performance
        object3D.material.dispose()
      }
    }

    object3D.removeFromParent() // the parent might be the scene or another Object3D, but it is sure to be removed this way
    return true
  }
}
