export default class XMLParser {
    constructor(xml_src) {
      this.xml_src = xml_src
      this.xml_dom = XMLParser.ParseXML(this.xml_src)
    }
  
    GetGLObject() {
      // Initialize the result object
      const result = {}
  
      // Iterate over each "Surfaces" element in the XML document
      this.xml_dom.querySelectorAll('Surfaces').forEach((Surfaces) => {
        let name = Surfaces.getAttribute('name')
  
        // If name attribute doesn't exist, generate a unique ID
        if (!name) {
          const uniqueId =
            Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
          name = `Unknown_${uniqueId}`
        } else if (result.hasOwnProperty(name)) {
          // If name already exists in result, append a unique ID to the original name
          const uniqueId =
            Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
          name = `${name}_${uniqueId}`
        }
        // Find the "Surface" element within the "Surfaces" element
        const surface = Surfaces.querySelector('Surface')
  
        // Throw an error if "Surface" element is not found within "Surfaces"
        if (!surface) {
          throw new Error(
            '<Surface></Surface> is not found in <Surfaces></Surfaces>.'
          )
        }
  
        // Check if the P elements are zero-indexed
        const isZeroIndexedPnt = !!surface.querySelector("P[id='0']")
        // Initialize arrays to store vertices and indices
        const vertices = []
        const indices = []
  
        // Iterate over each "P" element within the "Surface" element
        surface.querySelectorAll('P').forEach((P) => {
          // Get the id attribute of the "P" element
          const id = P.getAttribute('id')
          // Split and parse the text content of the "P" element into numbers
          const _data = P.textContent?.split(' ').map(Number)
  
          // Throw an error if id or data is invalid
          if (!id || !_data || _data.some(isNaN)) {
            throw new Error(`Invalid data for <P id="${id}"></P>.`)
          }
  
          const data = [_data[1], _data[0], _data[2]]
  
          // Add parsed data to the vertices array
          vertices.push(...data)
        })
  
        // Iterate over each "F" element within the "Surface" element
        surface.querySelectorAll('F').forEach((F) => {
          // Split and parse the text content of the "F" element into numbers, adjusting indices if necessary
          const data = F.textContent
            ?.split(' ')
            .map((x) => parseInt(x) - (isZeroIndexedPnt ? 0 : 1))
  
          // Skip if data is invalid
          if (!data || data.length < 2 || data.some(isNaN)) {
            return
          }
  
          // Add parsed data to the indices array
          indices.push(...data)
        })
  
        // Assign vertices and indices to the result object with the name as the key
        result[name] = { vertices, indices }
      })
  
      // Return the result object
      return result
    }
  
    static ParseXML(xml_src) {
      return new DOMParser().parseFromString(xml_src, 'text/xml')
    }
  }
  