import {Divider, Row, Col, Space, Button, Modal, Form, Input, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CheckOutlined, DeleteOutlined} from "@ant-design/icons";
import SelectField from "../../components/Fields/SelectField";
import DateField from "../../components/Fields/DateField";
import http from '../../http';
import MachineProjectListPage from "./MachineProjectListPage";
import DataTable from "../../components/DataTable/DataTable";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import {getDateString, getReadableDateFromTimeStamp} from "../../utilities/common";
import CustomTimeline from "../../components/Timeline/Timeline";
import MachinePosition from "./MachinePosition";

const MachineDetailPage=(props)=>{
    const location = useLocation();
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [reload, setApplicationReload] = useState(false);
    const [pendingApplicationReload, setPendingApplicationReload] = useState(false);
    const [returnApplicationReload, setReturnApplicationReload] = useState(false);
    const [machine, setMachine] = useState(state?.machine);
    const navigate = useNavigate();
    const [applicationInitialData, setApplicationInitialData] = useState({})
    const [formErrors, setFormErrors] = useState(false);
    const [applicationEdit, setApplicationEdit] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.machine);
    const [machineSchedules, setMachineSchedule] = useState([])

    const handleMachineReturn=()=>{
        http.post(`/api/v1/machines/return_requests/add/`, {
            machine: machine.id,
            application_date: getDateString(new Date())
        }).then((resp)=>{
            if(resp.status === 201){
                message.success(t('machine_return_application_create_succesful_text'));
                fetchMachineData();
                setReturnApplicationReload(!returnApplicationReload);
            }
            else{
                message.error((resp.data.error ? t(resp.data.error) : '') || t('machine_return_application_creation_failed_text'))
            }
        }).catch((err)=>{
            if(err.response){
                message.error((err.response.data.message ? t(err.response.data.message) : '') || (err.response.data.error ? t(err.response.data.error): '') || 'An error occurred, please' +
                    ' contact support team!');
            }
        })
    }

    const handleMachineReturnSubmit=(record)=>{
        http.post(`/api/v1/machines/return_requests/${record.id}/submit/`, ).then((resp)=>{
            if(resp.status === 200){
                message.success(t('machine_return_successful_text'))
                fetchMachineData();
                setPendingApplicationReload(!pendingApplicationReload)
                setReturnApplicationReload(!returnApplicationReload)
            }
            else{
                message.error((resp.data.error ? t(resp.data.error) : '') || t('machine_return_operation_failed_error'))
            }
        }).catch((err)=>{
            if(err.response){
                message.error((err.response.data.message ? t(err.response.data.message) : '') || (err.response.data.error ? t(err.response.data.error): '') || 'An error occurred, please' +
                    ' contact support team!');
            }
        })
    }

    const handleMachineReturnDelete=(record)=>{
        http.delete(`/api/v1/machines/return_requests/${record.id}/delete/`, ).then((resp)=>{
            if(resp.status === 204){
                message.success(t('machine_return_application_delete_successful_text'))
                setReturnApplicationReload(!returnApplicationReload);
                fetchMachineData()
            }
            else{
                message.error((resp.data.error ? t(resp.data.error) : '') || t('machine_return_application_delete_failed_text'))
            }
        }).catch((err)=>{
            if(err.response){
                message.error((err.response.data.message ? t(err.response.data.message) : '') || (err.response.data.error ? t(err.response.data.error): '') || 'An error occurred, please' +
                    ' contact support team!');
            }
        })
    }


    const fetchMachineData=()=>{
        http.get(`/api/v1/machines/${machine?.id}/`).then((resp)=>{
            setLoading(false);
            setMachine(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }
    const fetchMachineSchedule=()=>{
        http.get(`/api/v1/machines/${machine?.id}/running_schedules/`, ).then((resp)=>{
            if(resp.status === 200){
                let scheduleData = resp.data.map(item => ({
                    ...item,
                    marginTop: '5px',
                }));

                // For drawing the rectangle layer
                const rentalPeriodLayer =  {
                    id: 10001,
                    group: 1,
                    title: "",
                    start_time: machine.active_machine?.start_date,
                    end_time: machine.active_machine?.end_date,
                    color: 'transparent',
                    borderColor: '#CE7F00',
                    borderWidth: '2px',
                    padding: '13px'
                };
                const items = [...scheduleData, rentalPeriodLayer]
                setMachineSchedule(items)
            }
        }).catch((err)=>{
            setLoading(false);
        })
    }
    const fetchDefaultData =()=>{
        fetchMachineData()
        fetchMachineSchedule()
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found', {replace: true})
        }
        if(isValidState) {
            fetchDefaultData()
        }
    }, [state])

    const handleApplicationDelete = (record)=>{
        if(record.status === 0 || record.status === 3){
            http.delete(`/api/v1/machines/rental_applications/${record.id}/delete/`).then((resp)=>{
                if(resp.status === 204){
                    message.success(t('duration_changerequest_delete_successful_text'));
                    fetchDefaultData();
                    setApplicationReload(!reload)
                    setPendingApplicationReload(!pendingApplicationReload)
                }
                else{
                    message.error(t('duration_changerequest_delete_failed_text'));
                }
            })
        }
    }

    const handleApplicationEdit = (record)=>{
        setApplicationInitialData({});
        setIsModalVisible(true);
        setApplicationEdit(true);
        setEditItem(record);
        let dataUrl = `/api/v1/machines/rental_applications/${record?.id}/`;
        http.get(dataUrl).then((resp)=>{
            setApplicationInitialData(resp.data);
            setIsModalVisible(true)
            form.setFieldsValue(resp.data);
            setApplicationReload(!reload)
            setPendingApplicationReload(!pendingApplicationReload)

        }).catch((err)=>{
            message.error('Loading Data Failed')
        })
    }

    const renderEditIcon=(record)=>{
        if(record.status === 0 && record.active){
            return <Button
                className={'bordered-default-button'}
                onClick={()=>handleApplicationEdit(record)}
            >{t('rental_extension_edit_button_text')}
            </Button>
        }
        return null;
    }

    const columns = [
        {
            title: t('machine_usagerecord_list_col1'),
            dataIndex: 'from_date',
            key: 'from_date',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title:  t('machine_usagerecord_list_col2'),
            dataIndex: 'to_date',
            key: 'to_date',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('machine_usagerecord_list_project_col'),
            dataIndex: 'project_text',
            key: 'project_text',
        },
        {
            title: t('machine_usagerecord_list_col4'),
            dataIndex: 'manifest_text',
            key: 'manifest_name',
        }
    ];

    const handleSubmit=(record)=>{
        try {
            http.post(`/api/v1/machines/rental_applications/${record.id}/submit/`).then((resp)=>{
                if(resp.status === 200){
                    message.success(t('durationchange_request_succesful_text'));
                    fetchDefaultData();
                    setApplicationReload(!reload)
                    setPendingApplicationReload(!pendingApplicationReload)
                }
                else{
                    message.error(t('durationchange_request_failed_text'));
                }
            })
        }
        catch (err){
            message.error(t('durationchange_request_failed_text'));
        }
    }

    const applicationColumns = [
        {
            title: t('duration_changerequest_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
        },
        {
            title: t('duration_changerequest_col2'),
            dataIndex: 'from_date',
            key: 'extension_from_date',
        },
        {
            title: t('duration_changerequest_col3'),
            dataIndex: 'to_date',
            key: 'extension_to_date',
        },
        {
            title: t('duration_changerequest_col4'),
            dataIndex: 'status_text',
            key: 'status',
            render: (text) => (
                <div>{t(text)}</div>
            ),
        },
        {
            title: t('duration_changerequest_col5'),
//             dataIndex: 'description',
            key: 'description',
            render: (record)=> {
                return (
                    <>
                        <div>{record.reject_reason || record.description}</div>
                    </>
                )
            }
        },
        {
            title: t('duration_changerequest_col6'),
            dataIndex: 'triggered_by_text',
            key: 'applied_by',
        },
    ]

    const pendingApplicationColumns = [
        {
            title: t('duration_changerequest_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
        },
        {
            title: t('duration_changerequest_col2'),
            dataIndex: 'from_date',
            key: 'extension_from_date',
        },
        {
            title: t('duration_changerequest_col3'),
            // dataIndex: 'to_date',
            key: 'extension_to_date',
            render: (record)=> {
                if(record.current_end_date){
                    return (
                        <>
                            <div style={{color:'rgb(201, 143, 65)'}}>{record.to_date}</div>
                            <div>{record.current_end_date}</div>
                        </>
                    )
                }
                else{
                    return <div>{record.to_date}</div>
                }
            }
        },
        {
            title: t('duration_changerequest_col4'),
            dataIndex: 'status_text',
            key: 'status',
            render: (text) => (
                <div>{t(text)}</div>
            ),
        },
        {
            title: t('duration_changerequest_col5'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('duration_changerequest_col6'),
            dataIndex: 'triggered_by_text',
            key: 'applied_by',
        },
        {
            title: ' ',
            width:'23%',
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['rental-application-edit']}>
                        {renderEditIcon(record)}
                    </PermissionChecker>
                    {record.status === 0 && record.active ? <CustomConfirmPopup
                        title={t('duration_changerequest_submit_confirmation_text')}
                        onConfirm={()=>handleSubmit(record)}
                        icon={<CheckOutlined />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['rental-application-submit']}>
                            <Button type={'primary'} className={'primary-button'}>{t('rental_extension_submit_button_text')}</Button>
                        </PermissionChecker>
                    </CustomConfirmPopup>:null}
                    {(record.status === 0 || record.status === 3) && record.active ?<CustomConfirmPopup
                        title={t("duration_changerequest_delete_confirmation_text")}
                        onConfirm={()=>handleApplicationDelete(record)}
                        icon={<DeleteOutlined/>}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['rental-application-delete']}>
                            <Button className={'button-cancel'}>{t('rental_extension_delete_button_text')}</Button>
                        </PermissionChecker>
                    </CustomConfirmPopup>:null}
                </Space>
            )
        }
    ]

    const pendingReturnApplicationColumns = [
        {
            title: t('machine_return_request_table_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
        },
        {
            title: t('machine_return_request_table_col2'),
            dataIndex: 'status_text',
            key: 'status',
            render: (text) => (
                <div>{t(text)}</div>
            ),
        },
        {
            title: t('machine_return_request_table_col3'),
            dataIndex: 'triggered_by_text',
            key: 'applied_by',
        },
        {
            title: ' ',
            width:'23%',
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    {record.status === 0 ? <CustomConfirmPopup
                        title={t('return_request_submit_confirmation_text')}
                        onConfirm={()=>handleMachineReturnSubmit(record)}
                        icon={<CheckOutlined />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['machine-return-application-submit']}>
                            <Button type={'primary'} className={'primary-button'}>{t('machine_return_submit_button_text')}</Button>
                        </PermissionChecker>
                    </CustomConfirmPopup>:null}
                    {record.status === 0 ? <CustomConfirmPopup
                        title={t("machine_return_application_delete_confirmation_text")}
                        onConfirm={()=>handleMachineReturnDelete(record)}
                        icon={<DeleteOutlined/>}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['machine-return-application-delete']}>
                            <Button className={'button-cancel'}>{t('machine_return_delete_button_text')}</Button>
                        </PermissionChecker>
                    </CustomConfirmPopup>:null}
                </Space>
            )
        }
    ]

    const attachmentColumns = [
        {
            title: t('machine_detail_attachment_table_col1'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('machine_detail_attachment_table_col2'),
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <div style={{color:status === 1 ? 'green': 'blue'}}>{status === 1 ? t("attachment_active_status_text"):t
                    ("attachment_inactive_status_text")
                    }</div>
            ),
        },
    ]

    const transformBuckets=() =>{
        const result = [];
        if(machine){
            const defaultBucket = machine.default_bucket;
            const spareBuckets = machine.spare_buckets ? machine.spare_buckets.split(',') : [];

            // Add default bucket
            if (defaultBucket) {
                result.push({ name: defaultBucket.trim(), status: 1 });
            }

            // Add spare buckets
            spareBuckets.forEach(bucket => {
                const bucketName = bucket.trim();
                if (bucketName && bucketName !== defaultBucket) {
                    result.push({ name: bucketName, status:0});
                }
            });
        }

        return result;
    }


    const handleApplicationSubmit = (values) => {

        // TODO: Add logic to submit machine assignment to backend
        try {
            if(!applicationEdit){
                http.post('/api/v1/machines/rental_applications/add/', values).then((resp)=>{
                    if(resp.status === 201){
                        message.success(t('durationchange_request_succesful_text'));
                        form.resetFields();
                        fetchDefaultData();
                        setIsModalVisible(false);
                        setApplicationReload(!reload);
                        setPendingApplicationReload(!pendingApplicationReload)
                    }
                    else{
                        message.error(t('durationchange_request_failed_text'));
                    }
                }).catch((err)=>{
                    if(err?.response?.status === 400){
                        let errors = {};
                        let err_data = err?.response?.data || {};
                        for (const [key, value] of Object.entries(err_data.error_fields)) {
                            errors[key] = {validateStatus: false, errorMsg: t(value)}
                        }
                        if(Object.keys(errors).length !== 0){
                            message.error(t(err_data?.error) || t('default_error_connection_text'));
                            setFormErrors(errors)
                        }
                        else{
                            message.error(err_data?.validation_error);
                        }
                    }
                    else if(err?.response?.status === 500){
                        let err_data = err?.response?.data || t('default_unknown_error');
                        message.error(err_data?.error || t('default_unknown_error'))
                    }
                })
            }
            else{
                http.patch(`/api/v1/machines/rental_applications/${editItem.id}/edit/`, values).then((resp)=>{
                    if(resp.status === 200){
                        message.success(t('durationchange_request_edit_succesful_text'));
                        form.resetFields();
                        fetchDefaultData();
                        setIsModalVisible(false);
                        setApplicationEdit(false);
                        setEditItem(null);
                        setApplicationReload(!reload)
                        setPendingApplicationReload(!pendingApplicationReload)
                    }
                    else{
                        message.error(t('durationchange_request_failed_text'));
                    }
                }).catch((err)=>{
                    if(err?.response?.status === 400){
                        let errors = {};
                        let err_data = err?.response?.data || {};
                        for (const [key, value] of Object.entries(err_data.error_fields)) {
                            errors[key] = {validateStatus: false, errorMsg: value}
                        }
                        if(Object.keys(errors).length !== 0){
                            message.error(t(err_data?.error) || t('default_error_connection_text'));
                            setFormErrors(errors)
                        }
                        else{
                            message.error(err_data?.validation_error);
                        }
                    }
                    else if(err?.response?.status === 500){
                        let err_data = err?.response?.data || t('default_unknown_error');
                        message.error(err_data?.error || t('default_unknown_error'))
                    }
                })
            }
        }

        catch (err){
            message.error(t('durationchange_request_failed_text'));
        }
    };

    const renderApplicationForm = () => {
        const validateStatus = formErrors.hasOwnProperty(`to_date`) ? formErrors['to_date']?.validateStatus:null;
        const errorMsg = formErrors.hasOwnProperty(`to_date`) ? formErrors['to_date']?.errorMsg:null;
        return (
            <Modal
                title={Object.keys(applicationInitialData).length === 0  ? t('durationchange_addpage_title'): t('durationchange_editpage_title')}
                open={isModalVisible}
                onCancel={() => {form.resetFields(); setIsModalVisible(false); setFormErrors({})}}
                footer={[
                    <Button key="cancel" onClick={() => {form.resetFields(); setIsModalVisible(false); setFormErrors({})}}>
                        {t('addpage_cancel_button_text')}
                    </Button>,
                    <Button style={{background:'#377EF3'}} key="submit" type="primary" onClick={() => form.submit()}>
                        {Object.keys(applicationInitialData).length === 0 ? t('addpage_save_button_text'): t('addpage_edit_button_text')}
                    </Button>,
                ]}
            >
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col>{t('durationchange_currentstart_date_field')}: {machine.active_machine?.start_date}</Col>
                    <Col>{t('durationchange_currentend_date_field')}: {machine.active_machine?.end_date}</Col>
                </Row>
                <Divider />
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{machine: machine.id}}
                    onFinish={handleApplicationSubmit}
                >
                    <Form.Item label={t('durationchange_machine_field')} name={'machine'}>
                        <div style={{display:"flex"}}>
                            <Form.Item name={'machine'} style={{margin:'0px', width:'100%'}}>
                                <SelectField name={'machine'} url={`/api/v1/machines/combo/?id=${machine.id}`} value={machine.id} />
                            </Form.Item>
                            <div style={{ marginTop: '-4px', marginLeft: '2px', color:'#DC000C' }}>*</div>
                        </div>
                    </Form.Item>
                    <Form.Item label={t('durationchange_date_field')} name={'to_date'} validateStatus={validateStatus}
                               help={errorMsg}>
                        <DateField offset={7} initialValue={applicationInitialData['to_date']} />
                    </Form.Item>
                    <Form.Item label={t('durationchange_description_field')} name={'description'}>
                        <Input.TextArea rows={2} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{machine.name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/machines'}>{t('machine_listpage_title')} </Link> >  {machine.name}</>;
    }

    const getReturnRequestRowClassName=(record, index)=>{
        return 'highlight-datatable-row-yellow'
    }

    const getExtensionRequestRowClassName=(record, index)=>{
        if(record.status === 0) {
            return 'highlight-datatable-row-yellow'
        }
        else if(record.status === 1){
            return 'highlight-datatable-row-green'
        }
        else if (record.status === 3){
            return 'highlight-datatable-row-red'
        }
    }

    if(isValidState) {
        return (
            <>
                {renderApplicationForm(formErrors)}
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            {machine.active ?
                                <Space>
                                    <PermissionChecker requiredPermissions={['rental-application-create']}>
                                        <Button className={'bordered-default-button'} onClick={handleMachineReturn}>{t('machine_return_application_button_text')}</Button>
                                    </PermissionChecker>
                                    <PermissionChecker requiredPermissions={['rental-application-create']}>
                                        <Button disabled={!machine.can_make_change_request} style={{background: '#377EF3', color:'white'}} type={'primary'} onClick={() => {
                                            if(machine.can_make_change_request) {
                                                setApplicationInitialData({});
                                                setIsModalVisible(true)
                                            }
                                        }}>{t('durationchange_button_text')}</Button>
                                    </PermissionChecker>
                                </Space>:
                                null}

                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{machine.name}</h1></Col>
                        <Col>
                            <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Col span={10} style={{display:'flex'}}>
                                    <Col>
                                        <Col>{t('machine_carnumber_field')}: {machine.number}</Col>
                                        <Col>{t('machine_rentalstart_field')}: {machine.active_machine?.start_date}</Col>
                                        <Col>{t('machine_expectedreturn_field')}: {machine.active_machine?.end_date}</Col>
                                        <Col style={{color: machine?.machine_status?.color}}>{t('machine_status_field')}: {t(machine.machine_status?.status_text)}</Col>
                                    </Col>
                                </Col>
                                <Col span={12} style={{marginRight:'20px'}}>
                                    <MachinePosition />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    {machine?.has_schedule ?
                        <Row style={{marginTop: '50px'}}>
                            <CustomTimeline data={machineSchedules} title={'Machine Running Schedule'}/>
                        </Row>:<></>}
                    {!machine.show_request_list ?
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col span={12}>
                            <p style={{fontWeight: 'bold', fontSize: '800'}}>{t('rental_period_extension_request_list_title')}</p>
                        </Col>
                        <Col>
                            <DataTable
                                reload={pendingApplicationReload}
                                pageTitle={''}
                                columns={pendingApplicationColumns}
                                url={`/api/v1/machines/rental_applications/?machine=${machine.id}&status_in=0,1,3&active=1`}
                                pagination={true}
                                pageButtons={[]}
                                hideHeader={true}
                                rowClassName={getExtensionRequestRowClassName}
                            />
                        </Col>
                    </Row>:null}
                    {machine.has_return_request ? <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col span={12}>
                            <p style={{fontWeight: 'bold', fontSize: '800'}}>{t('machine_return_request_list_title')}</p>
                        </Col>
                        <Col>
                            <DataTable
                                reload={returnApplicationReload}
                                pageTitle={''}
                                columns={pendingReturnApplicationColumns}
                                url={`/api/v1/machines/return_requests/?machine=${machine.id}&status=0`}
                                pagination={true}
                                pageButtons={[]}
                                hideHeader={true}
                                rowClassName={getReturnRequestRowClassName}
                            />
                        </Col>
                    </Row>:null}

                    {machine.active ?<>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={reload}
                            pageTitle={t('machine_attachmentslist_title')}
                            columns={attachmentColumns}
                            data={transformBuckets()}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                        <Divider/>
                        <Row style={{overflow: "hidden", height: '60%'}}>
                            <div style={{width: '100%', height: '100%'}}>
                                <MachineProjectListPage pageSize={3} machine={machine} link={[{
                                    to: '/machines/',
                                    state: {},
                                    title: t('machine_listpage_title')
                                }, {to: '/machine/detail/', state: {machine: machine}, title: machine.name}]}/>
                            </div>
                        </Row>
                    </>:null
                    }
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={reload}
                            pageTitle={t('machine_durationchangerequestlist_title')}
                            columns={applicationColumns}
                            url={`/api/v1/machines/rental_applications/?machine=${machine.id}&active=1`}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={loading}
                            pageTitle={t('machine_usagerecord_listpage_title')}
                            columns={columns}
                            url={`/api/v1/projects/machine-assignments/?machine=${machine.id}`}
                            searchFields={[]}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default MachineDetailPage;