import React, { useState, useEffect } from "react";
import {message, Tabs, Table, Checkbox, Button, Form, Col, Row, Divider} from "antd";
import http from '../../http'
import {PlusOutlined} from "@ant-design/icons";
import classes from './UserPermission.module.css'
import GroupAddPage from "./GroupAddPage";
import RoleAddPage from "./RoleAddPage";
import GroupRoleAddPage from "./GroupRoleAddPage";
import {useTranslation} from "react-i18next";

const { TabPane } = Tabs;


const UserPermission = () => {
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState("1");
    const [selectedRow, setSelectedRow] = useState();
    const [systemUrls, setSystemUrls] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkedModules, setCheckedModules] = useState([]);
    const [groupRoleData, setGroupRoleData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [showGroupRoleModal, setShowGroupRoleModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [paginationObject, setPagination]= useState({
        page: 1,
        page_size: 10
    })
    const { t } = useTranslation();

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleRowClick = (record, rowIndex) => {
        setSelectedRow(record)
        if(record && activeTab === '1'){
            setCheckedItems(record.urls)
        }
        // update content of right div based on clicked row
    };

    const fetchGroupRoleData = (params)=>{
        http.get('/api/v1/auth/group_roles', {params: {...paginationObject, ...params}}).then((resp)=>{
            setGroupRoleData(resp.data.results);
            let paginationData = {...paginationObject, total: resp.data.count}
            setPagination(paginationData)
            if(resp.data.results.length > 0 && activeTab === '1') {
                let record = resp.data.results[0]
                if(selectedRow){
                    resp.data.results.forEach((d)=>{
                        if(d.id === selectedRow.id){
                            record = d;
                        }
                    })

                }
                handleRowClick(record);
            }
        });
    }

    useEffect(()=>{
        http.get('/api/v1/system_urls').then((resp)=>{
            setSystemUrls(resp.data.urls);
        })
        fetchGroupRoleData({tenant: 1})
        http.get('/api/v1/auth/users').then((resp)=>{
            setUserData(resp.data.results)
        });

    }, [reload])

    useEffect(() => {
        const activeDataSource = activeTab === "1" ? groupRoleData : userData;
        const firstRecord = activeDataSource.length > 0 ? activeDataSource[0]: null;
        // setSelectedRow(firstRecord)
        handleRowClick(firstRecord);
    }, [activeTab]);

    const handleSubmit = (values) => {
        http.patch(`/api/v1/auth/group_roles/${selectedRow?.id}/edit/`, {urls: checkedItems}).then((resp)=>{
            if(resp.status === 200){
                message.success(`Permission for ${selectedRow?.name} Updated Successfully`);
                setReload(!reload);
                setCheckedModules([]);
            }
            else{
                message.error(`Permission for ${selectedRow?.name} Couldn't be Updated!`);
            }
        })
    };
    const handleUserGroupRoleSubmit = (values) => {
        setReload(!reload);
        // http.patch(`/api/v1/auth/group_roles/${selectedRow?.id}/edit/`, {urls: checkedItems}).then((resp)=>{
        //     if(resp.status === 200){
        //         message.success(`Permission for ${selectedRow?.name} Updated Successfully`);
        //         setReload(!reload);
        //         setCheckedModules([]);
        //     }
        //     else{
        //         message.error(`Permission for ${selectedRow?.name} Couldn't be Updated!`);
        //     }
        // })
    };

    const handleCheckBoxClick=(item)=>{
        if (checkedItems.includes(item)) {
            // remove the value from the array
            const filteredItems = checkedItems.filter(val => val !== item);
            setCheckedItems(filteredItems);
        } else {
            // add the value to the array
            const newItems = checkedItems.concat(item);
            setCheckedItems(newItems);
        }
    }

    const handleAllSelect=(key)=>{
        if (checkedModules.includes(key)) {
            // remove the value from the array
            const filteredModules = checkedModules.filter(val => val !== key);
            setCheckedModules(filteredModules);
            const filteredItems = checkedItems.filter(val => !Object.keys(systemUrls[key]).includes(val));
            setCheckedItems(filteredItems);
        } else {
            // add the value to the array
            const newModules = checkedModules.concat(key);
            setCheckedModules(newModules);
            const newItems = checkedItems.concat(Object.keys(systemUrls[key]));
            setCheckedItems(newItems);
        }

    }

    const buildURLPermission=()=>{
        if(selectedRow) {
            return <Col span={16} style={{height: '100%', overflowY: "scroll"}}>
                <Row style={{marginBottom: '10px', fontWeight: 'bold', fontSize: '800'}}>
                    Permission for {t(selectedRow?.name)}
                </Row>
                <Divider/>
                <Form form={form} onFinish={handleSubmit}>
                    <Row style={{overflowY: "scroll"}}>
                        <Col span={10}>
                            {systemUrls ? Object.keys(systemUrls).slice(0, Object.keys(systemUrls).length / 2 + 1).map((key, index) => {
                                if (Object.keys(systemUrls[key]).length > 0) {
                                    return <Row>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            borderTop: '1px solid #E9F0FD',
                                            borderBottom: '1px' +
                                                ' solid #E9F0FD',
                                            backgroundColor: '#E9F0FD',
                                            color: '#5786EC',
                                            margin: '5px'
                                        }}>
                                            <div> {key} </div>
                                            <Checkbox checked={checkedModules.includes(key)} onChange={() => handleAllSelect(key)}/></div>
                                        {Object.keys(systemUrls[key]).map((elem, ind) => {
                                            return <Form.Item name="urls" key={ind}
                                                              style={{margin: '0px', width: '100%'}}>
                                                <Checkbox checked={checkedItems.includes(elem)}
                                                          onChange={() => handleCheckBoxClick(elem)}>
                                                    {systemUrls[key][elem].readable_name}
                                                </Checkbox>
                                            </Form.Item>
                                        })}
                                    </Row>
                                }

                            }) : null}
                        </Col>
                        <Col span={2}></Col>
                        <Col span={10}>
                            {systemUrls ? Object.keys(systemUrls).slice(Object.keys(systemUrls).length / 2 + 1, Object.keys(systemUrls).length).map((key, index) => {
                                if (Object.keys(systemUrls[key]).length > 0) {
                                    return <Row>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            color: '#5786EC',
                                            borderTop: '1px solid #E9F0FD',
                                            borderBottom: '1px' +
                                                ' solid #E9F0FD',
                                            backgroundColor: '#E9F0FD',
                                            margin: '5px'
                                        }}>
                                            <div> {key} </div>
                                            <Checkbox checked={checkedModules.includes(key)} onChange={() => handleAllSelect(key)}/></div>
                                        {Object.keys(systemUrls[key]).map((elem, ind) => {
                                            return <Form.Item name="urls" key={ind}
                                                              style={{margin: '0px', width: '100%'}}>
                                                <Checkbox checked={checkedItems.includes(elem)}
                                                          onChange={() => handleCheckBoxClick(elem)}>
                                                    {systemUrls[key][elem].readable_name}
                                                </Checkbox>
                                            </Form.Item>
                                        })}
                                    </Row>
                                }

                            }) : null}
                        </Col>
                    </Row>
                    <div style={{
                        textAlign: 'center', marginTop: '20px', borderTop: '1px solid grey', borderBottom: '1px' +
                            ' solid grey', padding: '5px'
                    }}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            </Col>
        }
        return <Col span={16} style={{height: '100%', overflowY: "scroll"}}>
            <Row style={{marginBottom: '10px', fontWeight: 'bold', fontSize: '800'}}>
                No Group Role Found
            </Row>
            <Divider/>
        </Col>
    }

    const buildUserBlock=()=>{
        return <Col span={16} style={{height: '100%', overflowY: "scroll"}}>
            <Row style={{marginBottom: '10px', fontWeight:'bold', fontSize: '800'}}>
                Permission for {selectedRow?.username}
            </Row>
            <Divider />
            <Form form={form} onFinish={handleUserGroupRoleSubmit}>
                <Row style={{overflowY: "scroll"}}>
                    <Col span={10}>
                        {groupRoleData ? groupRoleData.map((item, index) => {
                            return <Row>
                                <Form.Item name="urls" key={index}
                                           style={{margin: '0px', width: '100%'}}>
                                    <Checkbox checked={checkedItems.includes(item.id)}
                                              onChange={() => handleCheckBoxClick(item.id)}>
                                        {item.name}
                                    </Checkbox>
                                </Form.Item>
                            </Row>
                        }) : null}
                    </Col>
                </Row>
                <div style={{
                    textAlign: 'center', marginTop: '20px', borderTop: '1px solid grey', borderBottom: '1px' +
                        ' solid grey', padding: '5px'
                }}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>
            </Form>
        </Col>
    }

    const getRowClassName = (record, index) => {
        return selectedRow?.id === record.id ? 'selected-row' : '';
    };

    const handleOpenGroupRoleModal = () => {
        setShowGroupRoleModal(true);

    };

    const handleCloseGroupRoleModal = () => {
        setShowGroupRoleModal(false);
    };
    const handleSuccessGroupRoleAdd = () =>{
        setShowGroupRoleModal(false);
        setReload(!reload);
    }

    const syncGroupRole=()=>{
        http.post('/api/v1/auth/group_roles/sync/').then((resp)=>{
            if(resp.status === 200){
                message.success('Group Role Sync Successful')
            }
            else{
                 message.error('Group Role Sync Failed!')
            }
        }).catch((err)=>{
            message.error('Group Role Sync Failed')
        })
    }

    const buildLeftHeader=()=>{
        if(activeTab === '1'){
            return <>
                <Col style={{fontWeight: "bold", fontSize:"20px"}}>Group Role List</Col>
                <Col><Button type="primary" shape={'circle'} icon={<PlusOutlined/>} onClick={handleOpenGroupRoleModal}></Button></Col>
                <Col><Button type="primary" onClick={syncGroupRole}>Sync</Button></Col>
            </>
        }
        return <Col style={{fontWeight: "bold", fontSize:"20px"}}>User List</Col>
    }

    const onPageChange = (page, pageSize)=>{
        setPagination({...paginationObject, page: page})
        fetchGroupRoleData( {...paginationObject, page: page, tenant: 1})
    }

    return (
        <Row style={{flexDirection:'row', justifyContent:'space-between', height: '100%', alignItems:"center"}}>
            <Col span={6} style={{overflowY:'scroll', height: '100%'}}>
                <Row style={{marginBottom: '10px', fontWeight: 'bold', fontSize: '800', justifyContent:'space-between'}}>
                    {buildLeftHeader()}
                </Row>
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    defaultActiveKey="1"
                >
                    <TabPane tab="Group Roles" key="1">
                        <Table
                            className={classes.DataTable}
                            dataSource={groupRoleData}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: () => handleRowClick(record, rowIndex),
                                };
                            }}
                            columns={
                                [
                                    {
                                        key: 'name',
                                        title:'Name',
                                        dataIndex:'name',
                                        render: (value) => {
                                            return <div>{t(value)}</div>
                                    },
                                        }
                                    ]
                                }
                            pagination={{...paginationObject, onChange: (page, pageSize) =>onPageChange(page, pageSize)}}
                            rowClassName={getRowClassName}
                        />
                        {/* columns */}
                        {/*</Table>*/}
                    </TabPane>
                    {/*<TabPane tab="Users" key="2">*/}
                    {/*    <Table*/}
                    {/*        className={classes.DataTable}*/}
                    {/*        dataSource={userData}*/}
                    {/*        onRow={(record, rowIndex) => {*/}
                    {/*            return {*/}
                    {/*                onClick: () => handleRowClick(record, rowIndex),*/}
                    {/*            };*/}
                    {/*        }}*/}
                    {/*        columns={[{key: 'name', title:'Username', dataIndex:'username'}]}*/}
                    {/*        pagination={false}*/}
                    {/*        rowClassName={getRowClassName}*/}
                    {/*        scroll={{ y: "max-content"}}*/}
                    {/*    />*/}
                    {/*    /!* columns *!/*/}
                    {/*    /!*</Table>*!/*/}
                    {/*</TabPane>*/}
                </Tabs>
            </Col>
            <Col span={2} style={{height: '100%', overflowY: "scroll"}}></Col>
            {activeTab === "1" ? buildURLPermission() : buildUserBlock()}
            <GroupAddPage />
            <RoleAddPage />
            <GroupRoleAddPage
                visible={showGroupRoleModal}
                onClose={handleCloseGroupRoleModal}
                onSuccessSubmit={handleSuccessGroupRoleAdd}
            />
        </Row>
    );
};

export default UserPermission;